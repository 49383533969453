<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Registro de evento en la Bitácora</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="registry_time"
              >
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.registry_time') }}</span><span class="text-danger">*</span>
                <b-form-input
                  id="registry_time"
                  v-model="elementData.registry_time"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="folder_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.management_medium') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.management_medium')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.management_medium_id"
                    :options="mediumList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(mediumList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="folder_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.reason') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.reason')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.reason_id"
                    :options="reasonList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(reasonList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="folder_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.result') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.result')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.result_id"
                    :options="resultList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(resultList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="folder_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.observations') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.observations')"
                  rules="required"
                >
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.observations"
                    maxlength="500"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    reasonList: {
      type: Array,
      required: true,
    },
    resultList: {
      type: Array,
      required: true,
    },
    mediumList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    router() {
      return router
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('reasonList' in this.elementData) {
            delete this.elementData.reasonList
          }
          if ('resultList' in this.elementData) {
            delete this.elementData.resultList
          }
          if ('mediumList' in this.elementData) {
            delete this.elementData.mediumList
          }
          this.$parent.save(this.elementData)
        }
      })
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>

<style>
.datetime input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    display: block;
    width: 100%;
    height: 2.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
